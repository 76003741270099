import React from "react";
import Card from "react-bootstrap/Card";

function Information() {
  return (
    <Card className="my-3">
      <Card.Body>
        <Card.Title>Welcome to Tune Me Studio!</Card.Title>
        <Card.Text>
          <p>
            Whether you’ve attended a workshop, private lessons, or are finding us out of curiosity, we welcome you! Give us a like and let’s stay connected 🌱
          </p>
          <p>
          Joni Clare is a Songwriter, Performer, Educator, and Healing Arts practitioner in the Northwoods of Wisconsin.
          </p>
          <p>
            <strong>Tune Me Studio</strong> <br /> Creativity + Wellness offering Alexander Technique, Vibrational Sound Therapy, and Music Services.
          </p>
          <p>
            <strong>Contact Information:</strong>
          </p>
          <ul>
            <li>Location: Minocqua, WI, United States, Wisconsin</li>
            <li>Email: Joni@tunemestudio.com</li>
            <li>Phone: (715) 490-4566</li>
            <li>Services:  In-person classes, online classes, community and private workshops, music entertainment</li>
          </ul>
        </Card.Text>
      </Card.Body>
    </Card >
  );
}

export default Information;
