// src/components/Contact.js
import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const Contact = () => {
  return (
    <Card className="my-3 mx-auto" style={{ maxWidth: '1000px' }}>
      <Card.Body>
        <Card.Title>Contact Me</Card.Title>
        <Card.Text>Email: Joni@tunemestudio.com</Card.Text>
        <Card.Text>Phone: (715) 490-4566</Card.Text>
        <Card.Text>Location: Minocqua, WI, United States, Wisconsin</Card.Text>
        <Card.Text>Services: In-person classes, online classes, community and private workshops, music entertainment</Card.Text>
        <Card.Text>
          Message us on{' '}
          <a
            href="https://www.facebook.com/JoClareD"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>{' '}
          or Instagram as well!
        </Card.Text>
        <Button variant="primary" href="mailto:joni@tunemestudio.com">
          Email Us
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Contact;
