import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

const Piano = () => {
    return (
        <Container>
            <Card className="my-3">
                <Card.Body>
                    <h2 className="piano-title">Piano</h2>
                    <p className="piano-text">
                        In piano lessons, you will achieve musical skills and expression through music fundamentals, improvisation, mindfulness awareness, and performance if desired. Joni uses the Piano Adventures book series as a foundation, but time is also spent off book. She is also open to working on materials that you bring in.
                    </p>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Piano;
