// src/components/GoogleReviews.js
import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../index.css'; // Ensure your CSS is imported

function GoogleReviews() {
  const reviews = [
    {
      text:
        `I highly recommend music lessons with Joni. 
        
        Her energetic and charismatic personality is delightful. Joni's knowledge base and experience shine through in her playful and engaging lessons that she personalizes for your specific goals. 
        
        Joni's incorporation of the Alexander Technique into her vocal lessons assists to deepen your understanding of the mechanics of singing. 
        
        In the short time we have been working together my vocal abilities have improved greatly. 
        
        I look forward to every session!`,
      name: "Carolyn O."
    },
    {
      text:
        `Joni's songwriting workshop was a game changer for me. 
        
        Her approach to teaching is so engaging and supportive. 
        
        I felt like I could express myself freely and explore new techniques that I hadn't considered before. 
        
        Highly recommend her workshops to anyone looking to enhance their songwriting skills!`,
      name: "A. H."
    },
    {
      text: `Joni is an exceptional music teacher. 
        
        She helped me prepare for my live performance with great tips and confidence-boosting exercises. 
        
        Her insights on stage presence and vocal technique were incredibly valuable. 
        
        Thanks to Joni, I had one of the best performances of my life!`,
      name: "Pat G."
    }
  ];

  return (
    <Card className="my-3">
      <Card.Body>
        <Card.Title className="title">Google Reviews</Card.Title>
        <Row>
          {reviews.map((review, index) => (
            <Col key={index} md={4}>
              <Card className="mb-3 review-card">
                <Card.Body>
                  <Card.Text className="review-text">
                    {review.text.split('\n').map((line, idx) => (
                      <React.Fragment key={idx}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </Card.Text>
                  <Card.Footer className="review-footer">
                    <small className="text-muted">{review.name}</small>
                  </Card.Footer>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
}

export default GoogleReviews;
