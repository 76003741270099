import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import alexanderImage1 from '../data/0.jpg';
import alexanderImage2 from '../data/1.jpg';
import facebookPosts from '../data/facebookPosts.json'; // Import the JSON file directly

const AlexanderTechnique = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const filteredPosts = facebookPosts
            .filter(post => post.message !== "No message" || post.image_url) // Filter posts
            .slice(0, 20); // Take the first 20 posts

        setPosts(filteredPosts);
    }, []);

    // Helper function to truncate text
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    return (
        <Container>
            <Card className="my-3">
                <Card.Body>
                    <Card.Title>Alexander Technique</Card.Title>
                    <p className="alexander-text">
                        The first thing people notice about you when you enter a room is posture, eye contact, gestures, and voice.
                    </p>
                    <p className="alexander-text">
                        Discover the secrets many professional musicians, actors, and athletes use to produce fluid performances. The Alexander Technique will teach you how to communicate with your whole self and achieve peak performance. It is utilized in professions of the business industry, education services, customer service industry, and even parenting. You will:
                    </p>
                    <ul className="alexander-list">
                        <li>Improve posture and spinal alignment.</li>
                        <li>Relieve and prevent pain.</li>
                        <li>Reduce strain and muscular tension.</li>
                        <li>Find better ways to manage stress.</li>
                        <li>Become more present, open, and emotionally available.</li>
                        <li>Increase range of motion, breathing capacity, and overall energy.</li>
                        <li>Improve balance and coordination.</li>
                        <li>Emanate more radiant facial expressions and projection of voice.</li>
                    </ul>
                </Card.Body>
                <Card.Img variant="top" src={alexanderImage1} alt="Alexander Technique" loading="lazy" />
                <Card.Img variant="top" src={alexanderImage2} alt="Alexander Technique" loading="lazy" />
            </Card>

            <Card className="my-3">
                <Card.Body>
                    <Card.Title>Latest Facebook Posts</Card.Title>
                    {posts.length > 0 ? (
                        <Row>
                            {posts.map((post) => (
                                <Col md={6} lg={4} key={post.id} className="my-3">
                                    <Card>
                                        {post.image_url && (
                                            <a href={`https://www.facebook.com/${post.id}`} target="_blank" rel="noopener noreferrer">
                                                <Card.Img
                                                    variant="top"
                                                    src={post.image_url}
                                                    className="facebook-post-image"
                                                    style={{ objectFit: 'fill', width: '100%' }}
                                                />
                                            </a>
                                        )}
                                        <Card.Body>
                                            <Card.Text>
                                                {post.message !== "No message" ? truncateText(post.message, 150) : ""}
                                            </Card.Text>
                                            <Card.Footer>
                                                <small className="text-muted">
                                                    {new Date(post.created_time).toLocaleString()}
                                                </small>
                                            </Card.Footer>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p>No posts available.</p>
                    )}
                </Card.Body>
            </Card>

            <Card className="my-3">
                <Card.Body>
                    <Card.Title>Success in the Alexander Technique</Card.Title>
                    <p className="alexander-text">
                        Celebrities like Hugh Jackman, Hilary Swank, Paul McCartney, and Margot Robbie have used the Alexander Technique as a secret weapon for delivering award-winning performances.
                    </p>
                    <p className="alexander-text">
                        Actors, dancers, singers, and musicians use the Alexander Technique worldwide. It is requisite at many prestigious schools such as Juilliard School in New York, Yale School of Drama, UCLA School of Theater, and countless others. It’s also used to train members of the New York Philharmonic Orchestra to achieve flawless performances.
                    </p>

                    <h3 className="alexander-subtitle">What is the Alexander Technique?</h3>
                    <p className="alexander-text">
                        The Alexander Technique falls into health, wellness, and prevention. It helps you unlearn harmful muscular patterns and retrain new and healthy ones that will set you up with high levels of awareness with how you use and move. You receive gentle muscle manipulation plus learn a set of practical activities that help you build the skill of ‘thinking in activity’.
                    </p>

                    <h3 className="alexander-subtitle">Alexander Technique Table Work</h3>
                    <p className="alexander-text">
                        Joni is also an Alexander Technique teacher, and this is a fantastic sub-skill for music. The Alexander Technique is the study of motor control, which is the regulation of movement and general ability of a person to initiate and direct muscle function and voluntary movements. The overall framework is to unlearn harmful habits and retrain specific muscle patterns that are associated with stress. The gentle manual guidance that she provides essentially helps bring your alignment into a different state that better sets up muscle use, respiration, and vocalization. And you eventually learn to bring these changes about yourself.
                    </p>
                    <p className="alexander-text">
                        It’s often a question of how much and what kind of work it takes to move efficiently and avoid tension and injury during singing and playing. And it’s easy to adopt unhealthy patterns because they often give us a desired result or make us feel right in what we are doing. Joni listens to your sound and looks at your whole self to see how you are functioning. She helps you see what you can undo instead of muscling or straining your way through. A developing skill in the technique sets you up with a more conscious process through music and can help you create a healthy, happy experience while alert and calm.
                    </p>
                    <p className="alexander-text">
                        The Alexander Technique aids health and well-being for all activities of life and it stimulates coordination to support the demands of piano technique. It helps you unlearn harmful habits and prevent stress and injury through a subtle approach.
                    </p>
                    <p className="alexander-text">
                        The gentle manual guidance that Joni provides, plus a student’s developing conscious thought-process, essentially helps bring alignment into a different state that better sets up muscle use, respiration, and vocalization.
                    </p>
                    <p className="alexander-text">
                        These changes can improve how we “perform” activities such as singing, playing piano, or making the bed by influencing how we communicate and regulate our motor control functioning.
                    </p>
                    <p className="alexander-text">
                        You will start off with a full first Alexander Technique lesson to acquire the fundamentals. All lessons following then start with 15 minutes of Alexander Technique to help the student build skill and awareness in themselves. This time includes work at the chair, lying down, and other various activities that involve movement through space.
                    </p>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default AlexanderTechnique;
