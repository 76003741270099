import React from "react";
import { Container, TabContainer } from "react-bootstrap";
import Card from "react-bootstrap/Card";

function TuneMeStudio() {
    return (
        <Container>
            <Card className="my-3">
                <Card.Body>
                    <Card.Title>Tune Me Studio - Alexander Technique 🌱 🧘‍♀️</Card.Title>
                    <Card.Text>
                        <p>
                            The Alexander Technique is a way to feel better, and move in a more relaxed and comfortable way... the way nature intended.
                        </p>
                        <p>
                            Here are some examples of how the Alexander Technique can help:
                        </p>
                        <ul>
                            <li>Improve posture and ease of movement</li>
                            <li>Reduce pain and tension</li>
                            <li>Enhance performance in music, acting, and sports</li>
                            <li>Develop greater self-awareness and mindfulness</li>
                        </ul>
                        <p>
                            At Tune Me Studio, we offer personalized sessions to help you integrate the principles of the Alexander Technique into your daily life.
                        </p>
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card>

                {/* <Card.Body>
                    Tune me Studio:
                    needs vibrational sound therapy info (short description and benefits)
                    absorbing frequency to help bring the body into balance and restoration using tuning forks and world resonate instruments including gong, crystal singing bowls and the human voice.
                    More listed benefits coming
                    Also needs a section for music entertainment/Joni Clare’s Music (private and public events with videos and music links)
                    OR we generalize what Tune Me Studio is here and we create drop-down for VST and
                    Music entertainment (change live performances to Live Entertainment)
                </Card.Body> */}

            </Card>
        </Container>
    );
}

export default TuneMeStudio;
