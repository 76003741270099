// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Header from './components/Header';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import Footer from './components/Footer';
import TuneMeStudio from './components/TuneMeStudio';
import MusicPage from './components/MusicPage';
import AlexanderTechnique from './components/AlexanderTechnique';
import VST from './components/VST';
import Piano from './components/Piano';

function App() {
  return (
    <Container fluid>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tune-me-studio" element={<TuneMeStudio />} />
        <Route path="/music" element={<MusicPage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/alexander-technique" element={<AlexanderTechnique />} />
        <Route path="/VST" element={<VST />} />
        <Route path="/piano" element={<Piano />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Container>
  );
}

export default App;
